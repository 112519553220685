import React, { useState } from "react";
import "./Fandq.css";
const qanda = [
  {
    id: 0,
    question:
      "What kinds of 3D file formats are compatible with your application?",
    answer: `We work with popular software like Revit, Blender, and SketchUp.\n Our application supports common 3D export file formats like FBX and OBJ.\n If you provide the project in a Twinmotion or Unreal Engine file, it will help us bring the project to life,\n closely aligning with your vision.`,
    show: false,
  },

  {
    id: 1,
    question: "What if I already possess my own 3D models?",
    answer: `If you have pre-existing 3D models, our team will seamlessly integrate them into the application. We support a range of formats, including 3DS Max, SketchUp, and Blender. As part of our service, we take care of model and material conversions. Furthermore, we enhance scenes, provide content creation, and facilitate customer engagement through online meetings. By fully utilizing your existing assets, we deliver captivating real estate presentations.`,
    show: false,
  },

  {
    id: 2,
    question: "What if I haven't created any 3D models?",
    answer: `If you haven't delved into 3D modeling, our team offers comprehensive 3D modeling services from the ground up. We can craft models tailored to your specific vision. Additionally, we provide a wide range of content creation options, including renders, animations, and immersive tours, all of which ensure that your projects shine, even if you don't have pre-existing 3D models. Moreover, we offer a variety of content creation choices, path-traced renders, and high-quality animations. These versatile tools empower you to effectively showcase your real estate projects, even when starting from scratch in the world of 3D modeling.`,
    show: false,
  },

  {
    id: 3,
    question: "How long does it take to create?",
    answer: `The time it takes to create varies depending on the complexity of the project and the amount of work our team needs to bring it to life. For small apartments, it can take as little as one week or even less. However, for larger projects, the timeline can vary significantly based on the project's complexity and size.`,
    show: false,
  },

  {
    id: 4,
    question: "Can I customize website interface to match my brand?",
    answer: `Yes, our team is capable of integrating the app into your website and customizing it to match your brand. If you don't have a website, our team can create a tailored website for you that meets your specific needs and preferences.`,
    show: false,
  },
];
const Fandq = () => {
  const [questions, setQuestions] = useState(qanda);
  const [isRotating, setIsRotating] = useState(false);

  const handleRotationToggle = () => {
    setIsRotating(!isRotating);
  };
  const questionHandler = (questionId) => {
    handleRotationToggle();
    const updatedQuestions = questions.map((element) => {
      if (element.id === questionId) {
        return { ...element, show: !element.show };
      } else {
        // Hide the answers for other questions
        return { ...element, show: false };
      }
    });
    setQuestions(updatedQuestions);
  };
  return (
    <div className="fq_wrapper" id="f&q">
      <div className="text_container">
        <h2>Frequently Asked Questions</h2>
      </div>
      {questions.map((question, index) => (
        <div key={question.id} className="fq_container">
          <div
            style={{
              padding: "8px",
              paddingInline: "20px",
              border: "2px solid gray",
              borderRadius: "40px",

              backgroundColor: question.show
                ? "rgb(27, 27, 27)"
                : "transparent",
            }}
            onClick={() => {
              questionHandler(question.id);
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{question.question}</h4>
              {/* {question.show ? ( */}
              <img
                src="images/arrow-up-circle-svgrepo-com.svg"
                style={{
                  width: "35px",
                  marginLeft: "5px",
                  transition: "transform 0.5s ease",
                  transform: `${
                    question.show ? "rotate(0deg)" : "rotate(180deg)"
                  }`,
                }}
                alt="arrow_up"
              />
              {/* // ) : (
              //   <img
              //     src="images/arrow-down-circle-svgrepo-com.svg"
              //     style={{
              //       width: "35px",
              //       marginLeft: "5px",
              //     }}
              //     alt="arrow_down"
              //   />
              // )} */}
            </div>
            {question.show && (
              <div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    width: "100%",
                    margin: "5px auto",
                  }}
                ></div>
                <p
                  style={{
                    color: "white",
                  }}
                >
                  {question.answer}
                </p>
              </div>
            )}
          </div>
        </div>
      ))}
      {/* <div
        style={{
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "50px auto",
        }}
      ></div> */}
    </div>
  );
};

export default Fandq;
