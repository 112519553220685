import React from "react";
import "./AboutUs.css";

const AboutUs = ({ setModal }) => {
  return (
    <div style={{ margin: "0px auto" }} id="aboutus">
      <div className="wrapper">
        <div className="text_container">
          <h2>Bring your design to life</h2>
          {/* <h5>
            At AC Archiviz, we redefine architecture with immersive 3D
            presentations. Our passion for innovation drives us to create
            unique, accessible experiences for architects, buyers, and
            enthusiasts. Join us on a journey to transform your architectural
            visions into reality.
          </h5> */}
        </div>
        <img className="img" src="images/logo_pc.png" alt="laptop" />
      </div>
      <div className="text_container">
        <h2>Who we are ?</h2>
        <h5>
          At AC Archiviz, we revolutionize architecture through captivating 3D
          presentations. Fueled by our dedication to innovation, we craft
          distinctive and easily accessible experiences tailored for architects,
          buyers, and enthusiasts alike. Embark with us on a transformative
          journey to bring your architectural visions to life.
        </h5>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "50px auto",
        }}
      ></div>
    </div>
  );
};

export default AboutUs;
