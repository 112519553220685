import React from "react";
import "./Services.css";

const Card = ({ title, body, image }) => {
  return (
    <div className="card_container">
      <div style={{ margin: "10px 0" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={`images/${image}.svg`} alt="icons" width={"100px"} />
        </div>
        <h4>{title}</h4>
        <h5
          style={{
            color: "white",
            textAlign: "center",
            width: "90%",
            margin: "0 auto",
          }}
        >
          {" "}
          {body}
        </h5>
      </div>
    </div>
  );
};

export default Card;
