import React, { Fragment, useState } from "react";
import "./Header.css";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const Header = ({ setModal }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleSetActive = (to) => {
    console.log(to);
  };
  return (
    <div>
      <div
        className="warraper"
        style={{
          position: "fixed",
          top: 0,
          zIndex: 80,
          backgroundColor: "#121212",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src="images/logo.svg" alt="logo" className="logo" />
          <h1 className="campany_name">AC ARCHIVIZ</h1>
        </div>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div
          className={`header_buttons${menuOpen ? "open" : ""}`}
          style={{ marginRight: "50px" }}
        >
          <ul>
            <li className="link">
              {" "}
              <Link
                activeClass="active"
                to="aboutus"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onSetActive={handleSetActive}
              >
                About us
              </Link>{" "}
            </li>
            <li className="link">
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onSetActive={handleSetActive}
              >
                Services
              </Link>
            </li>
            <li className="link">
              {" "}
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onSetActive={handleSetActive}
              >
                Projects
              </Link>{" "}
            </li>
            <li className="link">
              {" "}
              <Link
                activeClass="active"
                to="f&q"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onSetActive={handleSetActive}
              >
                F&Q
              </Link>
            </li>
          </ul>
          <div style={{ margin: "10px 0px 20px 0px" }}>
            <div
              onClick={() => {
                setModal(true);
              }}
              className="contuct_us"
            >
              <p style={{ fontSize: "18px", fontWeight: "700" }}>CONTACT US</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "3px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "0px auto",
        }}
      ></div>
    </div>
  );
};

export default Header;
