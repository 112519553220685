import React, { useRef, useState } from "react";
import css from "./Modal3D.module.css";
import YouTube from "react-youtube";

export default function Modal3D({ modal, setModal, videoId }) {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const modalCloseHandler = () => {
    document.body.classList.remove("active-modal");
    setModal(false);
  };

  const opts = {
    width: windowSize.current[0] / 1.5,
    height: windowSize.current[1] / 2,
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className={css.modal}>
      <div onClick={modalCloseHandler} className={css.overlay}></div>
      <div className={css.modal_content}>
        <div
          style={{
            position: "absolute",
            right: 30,
            top: 15,
            color: "white",
            fontSize: "25px",
            cursor: "pointer",
          }}
          onClick={modalCloseHandler}
        >
          X
        </div>
        <YouTube videoId={videoId} opts={opts} />
      </div>
    </div>
  );
}
