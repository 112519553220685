import React from "react";
import css from "./Footer.module.css";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div style={{ backgroundColor: "#121212" }}>
      <div
        style={{
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "15px auto",
        }}
      ></div>

      <div style={{ width: "90%", margin: "0px auto" }}>
        <div className={css.footer_container}>
          <div style={{ flex: 1 }}>
            <h4 className={css.title}>About Us</h4>
            <p className={css.text}>
              At AC Archiviz, we redefine architecture with immersive 3D
              presentations. Our passion for innovation drives us to create
              unique, accessible experiences for architects, buyers, and
              enthusiasts. Join us on a journey to transform your architectural
              visions into reality.
            </p>
          </div>
          <div style={{ flex: 1 }}>
            <h4
              style={{
                color: "white",
                textAlign: "center",
                width: "100%",
                fontSize: "1.2rem",
                marginBottom: "1.6em",
                marginTop: "1.6em",
              }}
            >
              Important Links
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Link
                activeClass="active"
                to="aboutus"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <p className={css.important_link}>About us</p>
              </Link>
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <p className={css.important_link}>Services</p>
              </Link>
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <p className={css.important_link}>Projects</p>
              </Link>
              <Link
                activeClass="active"
                to="f&q"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <p className={css.important_link}>F&Q</p>
              </Link>
            </div>
          </div>
          <div style={{ flex: 0.5 }}>
            <h4 className={css.title}>Contact Us</h4>
            <p className={css.text}>
              Phone: (+216) 20341724 / (+420) 776 874 745
            </p>
            <p className={css.text}>Email: acarchiviz@gmail.com</p>
            <div className={css.social_media_container}>
              <img
                style={{
                  height: "40px",
                  aspectRatio: "1/1",
                  margin: "5px",
                  cursor: "pointer",
                }}
                src="images/linkedin.svg"
                alt="linkedin"
                onClick={() => {
                  openInNewTab(
                    "https://www.linkedin.com/in/ac-archiviz-46062a283"
                  );
                }}
              />
              <img
                style={{
                  height: "40px",
                  aspectRatio: "1/1",
                  margin: "5px",
                  cursor: "pointer",
                }}
                src="images/facebook.svg"
                alt="facebook"
                onClick={() => {
                  openInNewTab(
                    "https://www.facebook.com/people/AC-Archiviz/100094525691973"
                  );
                }}
              />
              <img
                style={{
                  height: "50px",
                  aspectRatio: "1/1",
                  margin: "5px",
                  cursor: "pointer",
                }}
                src="images/youtube.svg"
                alt="youtube"
                onClick={() => {
                  openInNewTab("https://www.youtube.com/@ACArchiviz");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
