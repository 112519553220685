import React from "react";
import "./Services.css";
import Card from "./Card";

const Services = () => {
  const services = [
    {
      title: "3D Interactive Presentations for Architects",
      body: `Creating interactive presentations that allow architects to showcase their projects in both 3D and 2D aspects.
    Introducing different features and components of architectural projects with detailed visuals.`,
      image: "3d-architecture",
    },
    {
      title: "Interactive Interior Design Features",
      body: `Enabling architects to offer interactive apartment tours with features like changing furniture, paint, and tiles.
    Providing day and night time visualization options for interior spaces.`,
      image: "room",
    },
    {
      title: "Interactive Commercial 3D presentation",
      body: `We offers 3D presentations for entrepreneurs to showcase their projects on their websites. 
    Clients can explore properties, view features, and book apartments effortlessly.`,
      image: "online-shop_1",
    },
    {
      title: "3D modling and Rendring",
      body: `We offer 3D modeling and rendering services, turning your concepts into captivating visuals.`,
      image: "layout",
    },
    {
      title: "Creating Website for Architects and entrepreneurs",
      body: `Our custom website service creates stunning online platforms that resonate with your unique vision and captivate your audience.`,
      image: "coding",
    },
  ];

  return (
    <>
      <div className="wrapper" id="services">
        <div className="services_container ">
          <div
            className="card_container"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2>Discover the Depth of Our Expertise & Services</h2>
          </div>
          {services.map((service, index) => (
            <Card
              key={index}
              title={service.title}
              body={service.body}
              image={service.image}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "50px auto",
        }}
      ></div>
    </>
  );
};

export default Services;
