import React, { useState } from "react";
import css from "./Modal.module.css";
import emailjs from "@emailjs/browser";
import { Oval, TailSpin } from "react-loader-spinner";
export default function Modal({ modal, setModal }) {
  const [mailform, setMailForm] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });
  const [message, setMessage] = useState({ text: "", error: false });
  const [loading, isLoading] = useState(false);

  const sendEmailsHandler = (event) => {
    isLoading(true);
    event.preventDefault();
    console.log(mailform);
    if (
      mailform?.message.trim() === "" ||
      mailform?.user_name.trim() === "" ||
      mailform?.subject.trim() === "" ||
      mailform?.user_name.trim() === ""
    ) {
      setMessage({ text: "all fields are required", error: true });
      isLoading(false);
      return;
    }
    emailjs
      .send("service_7hxj22h", "template_swdk0sw", mailform, {
        publicKey: "xouBmC4XkiTnYJhhM",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessage({ text: "email sent successfully", error: false });
          setMailForm({
            user_name: "",
            user_email: "",
            subject: "",
            message: "",
          });
          isLoading(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setMessage({
            text: `email faild successfully ${error.text}`,
            error: true,
          });
          isLoading(false);
        }
      );
  };
  const changeHandler = (event) => {
    setMailForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  const modalCloseHandler = () => {
    document.body.classList.remove("active-modal");
    setModal(false);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <div className={css.modal}>
      <div onClick={modalCloseHandler} className={css.overlay}></div>
      <div className={css.modal_content}>
        <h2 style={{ color: "white" }}>Contact Us</h2>
        <p style={{ color: "white", fontWeight: "700" }}>Name</p>
        <input
          type="text"
          name="user_name"
          value={mailform.user_name || ""}
          onChange={changeHandler}
          className={css.email_input}
          placeholder="Name"
        />
        <p style={{ color: "white", fontWeight: "700" }}>Email</p>
        <input
          type="text"
          name="user_email"
          value={mailform.user_email || ""}
          onChange={changeHandler}
          className={css.email_input}
          placeholder="Email"
        />
        <p style={{ color: "white", fontWeight: "700" }}>Subject</p>
        <input
          type="text"
          name="subject"
          value={mailform.subject || ""}
          onChange={changeHandler}
          className={css.email_input}
          placeholder="Subject"
        />
        <p style={{ color: "white", fontWeight: "700" }}>Body</p>
        <textarea
          type="text"
          name="message"
          rows="5"
          value={mailform.message || ""}
          onChange={changeHandler}
          className={css.textarea_input}
          placeholder="message"
        />
        {message?.text && (
          <p
            style={{
              color: message?.error ? "red" : "white",
              textAlign: "center",
            }}
          >
            {message?.text}
          </p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
                    <button
            onClick={modalCloseHandler}
            disabled={loading}
            style={{
              margin: "10px",
              marginTop: "20px",
              padding: "10px 20px",
              borderRadius: "15px",
              fontWeight: "600",
              cursor: "pointer",
              backgroundColor: "transparent",
              color: "white",
              border: " 1px solid white",
            }}
          >
            Back
          </button>

          <button
            onClick={sendEmailsHandler}
            disabled={loading}
            style={{
              margin: "10px",
              marginTop: "20px",
              padding: "10px 20px",
              borderRadius: "15px",
              fontWeight: "600",
              cursor: "pointer",
              backgroundColor: "transparent",
              color: "white",
              border: " 1px solid white",
            }}
          >
            {loading ? (
              <TailSpin
                visible={true}
                height="15"
                width="15"
                color="white"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
