import { useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Modal from "./components/Modal";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Fandq from "./components/Fandq";
import Projects from "./components/Projects";
import Modal3D from "./components/Modal3D";
function App() {
  const [modal, setModal] = useState(false);
  const [modal3d, setModal3D] = useState(false);
  const [videoId, setVideoId] = useState("");
  return (
    <>
      {modal3d && (
        <Modal3D modal={modal3d} setModal={setModal3D} videoId={videoId} />
      )}
      {modal && <Modal modal={modal} setModal={setModal} />}
      <div className="App">
        <Header setModal={setModal}></Header>
        <AboutUs />
        <Services />
        <Projects setModal={setModal3D} setVideoId={setVideoId} />
        <Fandq />
        <Footer></Footer>
      </div>
    </>
  );
}

export default App;
