import React from "react";
import "./Projects.css";

const Projects = ({ setModal, setVideoId }) => {
  const listOfProject = [
    {
      projectName: " Torrevigía Project, Marbella, Spain",
      projectOwnerLogo: "manuel-burgos.svg",
      projectThumbNail: "Splash.png",
      ProjectVideoId: "qx1x8HQjRH4",
      projectOwnerLogoWidth: 50,
    },
    {
      projectName: " Esenyurt Housing Project, Istanbul, Turkiye",
      projectOwnerLogo: "HPP-Black.svg",
      projectThumbNail: "turkishProject.png",
      ProjectVideoId: "lRj234Xa3nI",
      projectOwnerLogoWidth: 75,
    },
    {
      projectName: " Pentagen Project, Czech Republic",
      projectOwnerLogo: "Studio-Reaktor.svg",
      projectThumbNail: "Pentagen_Thumbnail.png",
      ProjectVideoId: "WjcP_FMTlBM",
      projectOwnerLogoWidth: 150 ,
    },
  ];
  return (
    <div className="fq_wrapper" id="projects">
      <div className="text_container">
        <h2>Our Work</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          // backgroundColor: "red",
        }}
      >
        <div className="projects_container">
          {listOfProject?.map((project) => (
            <div
              className="project_card_container"
              onClick={() => {
                setVideoId(project.ProjectVideoId);
                setModal(true);
              }}
            >
              <img
                src={`images/${project.projectOwnerLogo}`}
                alt="icons"
                width={project.projectOwnerLogoWidth}
                style={{ position: "absolute", top: "3%", left: "38%" }}
              />

              <img
                src={`images/${project.projectThumbNail}`}
                alt="icons"
                style={{
                  width: "100%",
                  height: "100%",
                  minWidth: "300px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
              <h3
                style={{
                  position: "absolute",
                  bottom: "5%",
                  left: "5%",
                  color: "white",
                  fontSize: "30px",
                }}
              >
                {project.projectName}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "95%",
          margin: "50px auto",
        }}
      ></div>
    </div>
  );
};

export default Projects;
